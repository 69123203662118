<template>
	<div>
		<vs-row :class="showSkuTable ? 'flex flex-wrap gap-2' : ''" class="justify-between">
			<vs-col :vs-w="showSkuTable ? '5' : '12'">
				<p class="text-xl text-bold">List Item Units</p>
				<hr />
				<div class="flex">
					<vs-button color="primary" type="filled" size="small" class="my-4" icon="search"
						v-show="showBtnSearch" @click="showSkuTable = !showSkuTable">Search</vs-button>
					<!-- <vs-button
            color="danger"
            type="filled"
            size="small"
            class="my-4"
            icon="restore"
            v-show="!showBtnSearch"
            @click="clickRestore"
          /> -->
				</div>

				<!-- table selected line item -->
				<vs-table stripe :data="selectedItemUnits">
					<template slot="thead">
						<vs-th> # </vs-th>
						<vs-th> SKU </vs-th>
						<vs-th> HU </vs-th>
						<vs-th v-if="form.so_types == 'with_so_reference'">
							Qty Invoice
						</vs-th>
						<vs-th> Qty Return </vs-th>
						<vs-th>Reason</vs-th>
						<vs-th>Attachments </vs-th>
						<vs-th>Price</vs-th>
						<vs-th v-if="form.so_types == 'without_so_reference'">Adj. Price</vs-th>
						<!-- <vs-th>Sub Total</vs-th> -->
						<vs-th>Reg. Discount</vs-th>
						<vs-th>Tax</vs-th>
						<vs-th>Grand Total </vs-th>
					</template>

					<template slot-scope="{ data }">
						<vs-tr :key="indextr" v-for="(tr, indextr) in data">
							<vs-td>
								<vs-button v-show="showDeleteOnly(tr)" color="danger" type="filled" size="small"
									icon="delete" @click="handleDelete(tr)" />
							</vs-td>
							<vs-td>
								{{ tr.sku_code + " - " + tr.item_name }}
							</vs-td>
							<vs-td>
								{{ tr.unit_name }}
							</vs-td>
							<vs-td v-if="form.so_types == 'with_so_reference'">
								{{ tr.qty_invoice }}
							</vs-td>
							<vs-td>
								<NumberInput v-model="tr.qty" :min="0" :disabled="disabledInput" />
							</vs-td>
							<vs-td>
								<multiselect v-show="showDelete(tr)" :disabled="disabledInput" v-model="tr.reason"
									:options="optionsReason" :max-height="125" :allow-empty="true" :select-label="''"
									deselect-label="" :searchable="true" :custom-label="customLabelReason">
									<span slot="noResult">Oops! No data found</span>
								</multiselect>
							</vs-td>
							<vs-td>
								<InputFile v-show="showDelete(tr)" :isDisabled="disabledInput"
									:acceptedTypes="acceptedTypes"
									@data="setAttachment($event, tr.sku_code, tr.unit_name)"
									setPathUpload="sales-return-v2" :isWithAPI="true" />
							</vs-td>
							<vs-td>
								<span>{{ formatCurrencyIDR(tr.price) }}</span>
							</vs-td>
							<!-- <vs-td>
                <span>{{ formatCurrencyIDR(tr.subtotal) }}</span>
              </vs-td> -->
							<vs-td v-show="form.so_types == 'without_so_reference'">
								<NumberInput v-model="tr.adj_price" :min="0" :disabled="disabledInput" />
							</vs-td>
							<vs-td>
								{{ formatCurrencyIDR(tr.discount) }}
							</vs-td>
							<vs-td>
								{{ formatCurrencyIDR(tr.taxAmount) }}
							</vs-td>

							<vs-td>
								{{
			formatCurrencyIDR(
				parseFloat(tr.price) * parseFloat(tr.qty) +
				parseFloat(tr.taxAmount) -
				parseFloat(tr.discount)
			)
		}}
							</vs-td>
						</vs-tr>
					</template>
				</vs-table>

				<!-- return summary -->
				<vs-row class="flex my-2">
					<vs-col vs-w="4" vs-offset="8">
						<p class="text-xl">Return Summary</p>
						<hr />

						<div class="my-2">
							<label>Total List Price</label>
							<br />
							<h4 class="font-bold">
								{{
			formatCurrencyIDR(
				parseFloat(form.summary_data.total_list_price)
			)
		}}
							</h4>
						</div>
						<div class="my-2">
							<label>Total Tax</label>
							<br />
							<h4 class="font-bold">
								{{ formatCurrencyIDR(parseFloat(form.summary_data.total_tax)) }}
							</h4>
						</div>
					</vs-col>
				</vs-row>
			</vs-col>

			<!-- table choose item units -->
			<transition name="detail-fade">
				<vs-col v-if="showSkuTable" vs-w="6">
					<vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth"
						vs-w="4">
						<vs-button class="ml-auto" size="small" v-on:click="handleClose" color="grey"
							icon-pack="feather" icon="icon-x-square">Close</vs-button>
					</vs-col>
					<!-- <TableItemUnit
            @data="setItemUnit"
            ref="tableItemUnit"
            :itemUnitIDs="form.customer_invoice_data.item_unit_ids"
          /> -->
					<TableItem @data="setItem" ref="tableItem"
						:itemUnitIDs="form.customer_invoice_data.item_unit_ids" />
				</vs-col>
			</transition>
		</vs-row>
	</div>
</template>

<script>
import TableItemUnit from "@/components/master/TableItemUnit.vue";
import { formatCurrencyIDR } from "@/helpers/common.js";
import InputFile from "@/components/master/InputFile.vue";
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import SelectMasterReasonV2 from "@/components/master/SelectMasterReasonV2.vue";
import TableItem from "@/components/master/TableItem.vue";
import VueNumericInput from "vue-numeric-input";
import NumberInput from "@/components/master/NumberInput.vue";

const SalesReturnV2Repo = RepositoryFactory.get("salesReturnV2");
const masterRepository = RepositoryFactory.get("master");

export default {
	components: {
		TableItemUnit,
		InputFile,
		SelectMasterReasonV2,
		VueNumericInput,
		TableItem,
		NumberInput,
	},
	props: {
		form: {
			type: Object,
			required: true,
		},
		showBtnSearch: {
			type: Boolean,
			default: false,
		},
		isWithSoReference: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			disabledInput: false,
			optionsReason: [],
			selectedReason: null,
			showSkuTable: false,
			selectedItemUnits: [],
			groupedSalesReturnLine: [],
			// jpg, jpeg, png, pdf, doc, docx, xls, xlsx
			acceptedTypes: [
				"image/jpeg",
				"image/png",
				"application/pdf",
				"application/msword",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				"application/vnd.ms-excel",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			],
		};
	},
	mounted() {
		this.getReason();
		if (this.form.isEdit) {
			this.showBtnSearch = false;
			this.setSelectedItemUnits();
		}
		if (this.form.isDetail == 1) {
			this.showBtnSearch = false;
			this.disabledInput = true;
		}
		if (this.form.return_line_item_data) {
			this.selectedItemUnits = this.form.return_line_item_data.map((item) => {
				return {
					sales_return_line_id: item.sales_return_line_id,
					sku_code: item.item_sku_code,
					item_name: item.item_name,
					unit_name: item.item_unit,
					qty: parseFloat(item.quantity),
					qty_invoice: parseFloat(item.qty_invoice),
					so_price: item.price,
					price: parseFloat(item.price),
					taxAmount: item.tax,
					discount: item.reg_discount,
					adj_price: parseFloat(item.adj_price),
					invoice_line_id: item.invoice_line_id,
					reason: item.reason,
					parent: item.parent,
				};
			});

			// calculate summary
			this.calculateSummary(this.selectedItemUnits);
		}
	},
	methods: {
		showDeleteOnly(tr) {
			if (this.form.isEdit || this.form.isDetail == 1) {
				return false;
			}

			if (tr.parent) {
				if (this.form.isDetail) {
					return false;
				}
				return true;
			}
		},
		showDelete(tr) {
			if (tr.parent) {
				if (this.form.isDetail) {
					return false;
				}
				return true;
			}
		},
		handleDelete(tr) {
			// delete this.selectedItemUnits by sku code same
			this.selectedItemUnits = this.selectedItemUnits.filter(
				(item) => item.sku_code != tr.sku_code
			);
		},
		handleClose() {
			this.showSkuTable = false;
		},
		async getReason() {
			try {
				const resp = await masterRepository.getMasterReason({
					params: {
						reason_group: "Customer Return",
					},
				});
				if (resp.code == 200) {
					this.optionsReason = [];
					for (let i = 0; i < resp.data.records.length; i++) {
						this.optionsReason.push(resp.data.records[i].reason);
					}
				} else {
					this.$vs.notify({
						title: "Error",
						text: "Error while fetching master reason",
						color: "danger",
						iconPack: "feather",
						icon: "icon-alert-circle",
					});
				}
			} catch (error) {
				this.$vs.notify({
					title: "Error",
					text: "Error while fetching master reason",
					color: "danger",
					iconPack: "feather",
					icon: "icon-alert-circle",
				});
				console.log(error);
			}
		},
		setSelectMasterReason(data, index) {
			this.selectedItemUnits[index].reason = data.reason;
			this.form.return_line_item_data = this.selectedItemUnits;
		},
		convertDTO(item) {
			return {
				id: 0,
				parent: false,
				invoice_line_id: item.ID,
				sku_code: item.SkuCode,
				item_name: item.ItemName,
				unit_name: item.Unit,
				qty_invoice: parseFloat(item.Qty),
				qty: 0,
				so_price: parseFloat(item.Price),
				price: parseFloat(item.Price),
				subtotal: parseFloat(item.Subtotal),
				// adj price is selisih antara (so_price - discount) dengan price
				adj_price: 0,
				taxAmount: item.TaxAmount,
				discount: item.Discount,
				reason: "",
				attachments: [],
			};
		},
		async setAttachment(data, skuCode, unitName) {
			const index = this.selectedItemUnits.findIndex(
				(item) => item.sku_code == skuCode && item.unit_name == unitName
			);
			this.selectedItemUnits[index].attachments = data;
			this.form.return_line_item_data = this.selectedItemUnits;
		},
		async setSelectedItemUnits() {
			if (this.form.isEdit || this.form.isDetail == 1) {
				this.selectedItemUnits = [];
				this.form.return_line_item_data.map((item) => {
					let data = {
						parent: item.parent,
						invoice_line_id: item.invoice_line_id,
						sales_return_line_id: item.sales_return_line_id,
						sku_code: item.item_sku_code,
						item_name: item.item_name,
						unit_name: item.item_unit,
						qty: parseFloat(item.quantity),
						qty_invoice: parseFloat(item.qty_invoice),
						so_price: item.price,
						price: parseFloat(item.price),
						taxAmount: item.tax,
						discount: item.reg_discount,
						adj_price: parseFloat(item.adj_price),
						reason: item.reason,
						attachments: item.attachments,
					};
					this.selectedItemUnits.push(data);
				});
			} else {
				if (
					this.form.customer_invoice_data.invoice_code != null ||
					this.form.customer_invoice_data.invoice_code != ""
				) {
					const temps = this.form.customer_invoice_data.sales_order_lines_temp;
					this.selectedItemUnits = [];
					await temps.forEach((item) => {
						let newDataDto = this.convertDTO(item);
						this.selectedItemUnits.push(newDataDto);
					});
				}
			}
			this.groupingLine();
		},
		groupingLine() {
			let arrTemp = [];
			this.selectedItemUnits.map((item) => {
				console.log("selectedItemUnits : ", item);
				let temp = {
					// invoice_line_id: item.invoice_line_id,
					// sales_return_line_id: item.sales_return_line_id,
					// qty: parseFloat(item.quantity),
					// qty_invoice: parseFloat(item.qty_invoice),
					// so_price: item.price,
					// price: parseFloat(item.price),
					// taxAmount: item.tax,
					// discount: item.reg_discount,
					// adj_price: parseFloat(item.adj_price),
					sku_code: item.sku_code,
					item_name: item.item_name,
					reason: item.reason,
					attachments: item.attachments,
					units: [
						{
							invoice_line_id: item.invoice_line_id,
							sales_return_line_id: item.sales_return_line_id,
							unit_name: item.unit_name,
							qty: parseFloat(item.qty),
							qty_invoice: parseFloat(item.qty_invoice),
							so_price: item.price,
							price: parseFloat(item.price),
							taxAmount: item.tax,
							discount: item.reg_discount,
							adj_price: parseFloat(item.adj_price),
						},
					],
				};
				// this.selectedItemUnits.push(data);
				arrTemp.map((check) => {
					if (check.sku_code == item.sku_code) {
						check.units.push({
							invoice_line_id: item.invoice_line_id,
							sales_return_line_id: item.sales_return_line_id,
							unit_name: item.unit_name,
							qty: parseFloat(item.qty),
							qty_invoice: parseFloat(item.qty_invoice),
							so_price: item.price,
							price: parseFloat(item.price),
							taxAmount: item.tax,
							discount: item.reg_discount,
							adj_price: parseFloat(item.adj_price),
						});
					}
				});

				let check = arrTemp.filter(
					(arr_line) => arr_line.sku_code == item.sku_code
				);

				if (check.length == 0) {
					arrTemp.push(temp);
				}
			});
			this.groupedSalesReturnLine = arrTemp;
			console.log("arrTemp", arrTemp);
		},
		formatCurrencyIDR,
		setItem(data) {
			if (this.addValidation(data)) {
				this.$vs.loading();
				// get item hu
				SalesReturnV2Repo.getItemHU({
					sku_code: data.SkuCode,
				}).then((response) => {
					if (response.code == 200) {
						let responseUnits = response.data;

						responseUnits.forEach((item, index) => {
							// get price each item unit id
							const date = new Date().toISOString().split("T")[0];
							const warehouse_id =
								this.form.customer_return_data.territory_warehouse.warehouse_id;
							const sku_code = data.SkuCode;
							const item_unit_id = item.item_unit_id;
							const territory_id =
								this.form.customer_return_data.territory_warehouse.territory_id;
							const pricing_group_id = this.form.customer_data.pricing_group_id;
							const customer_group_id1 =
								this.form.customer_data.customer_group_id_1;
							const customer_group_id2 =
								this.form.customer_data.customer_group_id_2;
							const customer_group_id3 =
								this.form.customer_data.customer_group_id_3;
							const customer_id = this.form.customer_data.customer_id;
							const is_simulation = false;

							SalesReturnV2Repo.getPriceItem({
								date: date,
								warehouse_id: warehouse_id,
								sku_code: sku_code,
								item_unit_id: item_unit_id,
								territory_id: territory_id,
								pricing_group_id: pricing_group_id,
								cg1: customer_group_id1,
								cg2: customer_group_id2,
								cg3: customer_group_id3,
								customer_id: customer_id,
								is_simulation: is_simulation,
							}).then((responsePriceItem) => {
								let price = 0;
								if (responsePriceItem.code == 200) {
									price = responsePriceItem.data.price;
								}

								const newItem = {
									id: item.item_unit_id,
									parent: false,
									invoice_line_id: 0,
									sales_order_line_id: 0,
									sku_code: data.SkuCode,
									item_name: data.Name,
									unit_name: item.unit_name,
									qty: 0,
									so_price: price,
									price: price,
									adj_price: price,
									taxAmount: 0,
									discount: 0,
									reason: "",
									attachments: [],
								};

								if (index == 0) {
									newItem.parent = true;
								}

								this.selectedItemUnits.push(newItem);
								this.form.return_line_item_data = this.selectedItemUnits;

								this.$vs.loading.close();
							});
						});
					}
				});
			}
		},
		async setItemUnit(data) {
			if (this.addValidation(data)) {
				// get price item
				// TODO: this purpose is to get price item from RBP
				await this.$vs.loading();
				if (this.form.customer_return_data.territory_warehouse == null) {
					await this.$vs.loading.close();
					this.$vs.notify({
						title: "Error",
						text: "Please select warehouse first",
						color: "danger",
					});
					return;
				}

				let warehouse_id =
					this.form.customer_return_data.territory_warehouse.warehouse_id;
				let sku_code = data.code;
				let item_unit_id = data.item_unit_id;
				let territory_id =
					this.form.customer_return_data.territory_warehouse.territory_id;
				let pricing_group_id = this.form.customer_data.pricing_group_id;
				let customer_group_id1 = this.form.customer_data.customer_group_id_1;
				let customer_group_id2 = this.form.customer_data.customer_group_id_2;
				let customer_group_id3 = this.form.customer_data.customer_group_id_3;
				let customer_id = this.form.customer_data.customer_id;

				const responsePriceItem = await SalesReturnV2Repo.getPriceItem({
					warehouse_id: warehouse_id,
					sku_code: sku_code,
					item_unit_id: item_unit_id,
					territory_id: territory_id,
					pricing_group_id: pricing_group_id,
					cg1: customer_group_id1,
					cg2: customer_group_id2,
					cg3: customer_group_id3,
					customer_id: customer_id,
					is_simulation: false,
				});

				let price = 0;
				if (responsePriceItem.code == 200) {
					price = responsePriceItem.data.price;
				}

				this.selectedItemUnits.push({
					id: data.id,
					sales_order_line_id: 0,
					sku_code: sku_code,
					item_name: data.name,
					unit_name: data.unit,
					qty: 0,
					so_price: 0,
					price: price,
					adj_price: price,
					taxAmount: 0,
					discount: 0,
					reason: "",
					attachments: [],
				});
				this.form.return_line_item_data = this.selectedItemUnits;

				await this.$vs.loading.close();
			}
		},
		addValidation(data) {
			// if sku_code and  unit_name is exist
			if (
				this.selectedItemUnits.some((item) => item.sku_code == data.SkuCode)
			) {
				this.$vs.notify({
					title: "Error",
					text: "SKU Code and Unit Name is exist",
					color: "danger",
				});
				return false;
			}

			return true;
		},
		async calculateSummary(data) {
			// calculate adj price each row
			await data.forEach((item) => {
				if (this.form.so_types == "with_so_reference") {
					item.adj_price = 0;
					return;
				} else {
					item.price = parseFloat(item.price);
					item.adj_price = parseFloat(item.so_price) - parseFloat(item.price);
				}
			});

			// total list price
			const totalListPrice = data.reduce((acc, item) => {
				return acc + item.price * item.qty;
			}, 0);

			// total discount
			const totalDiscount = data.reduce((acc, item) => {
				return acc + item.discount * item.qty;
			}, 0);

			// total tax
			const totalTax = data.reduce((acc, item) => {
				return acc + item.taxAmount * item.qty;
			}, 0);

			// TODO: total net

			// TODO: total charges

			// TODO: total return

			this.form.summary_data.total_list_price = totalListPrice;
			this.form.summary_data.total_discount = totalDiscount;
			this.form.summary_data.total_tax = totalTax;
		},
		customLabelReason(option) {
			return `${option}`;
		},
	},
	watch: {
		selectedItemUnits: {
			handler: function (val) {
				this.form.return_line_item_data = this.selectedItemUnits;
				// calculate summary
				this.calculateSummary(val);
			},
			deep: true,
		},
		"form.customer_invoice_data.invoice_lines": {
			handler: function (val) {
				if (val != null) {
					this.selectedItemUnits = [];
					let allDtos = [];
					val.forEach((item) => {
						let newDataDto = this.convertDTO(item);
						allDtos.push(newDataDto);
					});
					// group by sku code
					let groupBySkuCode = allDtos.reduce((acc, item) => {
						if (!acc[item.sku_code]) {
							acc[item.sku_code] = [];
						}
						acc[item.sku_code].push(item);
						return acc;
					}, {});

					// make first line on each sku code as parent
					groupBySkuCode = Object.values(groupBySkuCode).map((item) => {
						item[0].parent = true;
						return item;
					});
					// push to selected item units
					groupBySkuCode.forEach((item) => {
						item.forEach((item) => {
							this.selectedItemUnits.push(item);
						});
					});
					this.form.return_line_item_data = this.selectedItemUnits;
					this.groupingLine();
				}
			},
			deep: true,
		},
		// "form.customer_invoice_data.sales_order_lines": {
		//   handler: function (val) {
		//     if (val != null) {
		//       this.selectedItemUnits = [];
		//       val.forEach((item) => {
		//         let newDataDto = this.convertDTO(item);
		//         this.selectedItemUnits.push(newDataDto);
		//       });
		//       this.form.return_line_item_data = this.selectedItemUnits;
		//     }
		//   },
		//   deep: true,
		// },
		// "form.isEdit": {
		//   handler: function (val) {
		//     if (val) {
		//       this.setSelectedItemUnits();
		//     }
		//   },
		//   deep: true,
		// },
	},
};
</script>
